import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone'; // Add this import at the top of the file
import { fetchAllSelections, getCurrentGame, getGameweekResults } from './api';
import Card from './components/Card';
import LoadingSpinner from './components/LoadingSpinner';
import styles from './AllSelections.module.css';

function AllSelections() {
  const [selections, setSelections] = useState([]);
  const [currentGame, setCurrentGame] = useState(null);
  const [gameweekResult, setGameweekResult] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const formatKickoffTime = (dateString) => {
    const date = moment(dateString).tz('Europe/London');
    return date.format('dddd, MMMM D, YYYY h:mm A z'); // e.g., "Saturday, August 24, 2024 12:30 PM BST"
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        console.log('Fetching data for AllSelections...');
        const [selectionsData, gameData, gameweekResultData] = await Promise.all([
          fetchAllSelections(),
          getCurrentGame(),
          getGameweekResults().catch(err => {
            console.log('Error fetching gameweek results:', err);
            return null;
          })
        ]);
        console.log('Selections data:', selectionsData);
        console.log('Current game:', gameData);
        console.log('Gameweek result:', gameweekResultData);
        setSelections(selectionsData || []);
        setCurrentGame(gameData);
        setGameweekResult(gameweekResultData);
        setIsLoading(false);
      } catch (err) {
        console.error('Error loading data:', err);
        handleError(err);
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  const handleError = (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 403:
          setError('Selections are not yet visible. Check back after the deadline has passed.');
          break;
        case 404:
          if (error.response.data.message === 'No active game found') {
            setError('No active game found. Please check back later.');
          } else {
            setError('No data found. Please check back later.');
          }
          break;
        default:
          setError('An error occurred while fetching data. Please try again later.');
      }
    } else if (error.request) {
      setError('Unable to reach the server. Please check your connection and try again.');
    } else {
      setError('An unexpected error occurred. Please try again later.');
    }
  };

  if (isLoading) return <LoadingSpinner />;
  if (error) return <Card><div className={styles.error}>{error}</div></Card>;

  const isDeadlinePassed = currentGame && new Date(currentGame.deadline) < new Date();

  const getPlayerStatus = (playerName) => {
    if (!gameweekResult || !gameweekResult.results) return 'pending';
    const result = gameweekResult.results.find(r => r.player === playerName);
    if (!result) return 'pending';
    return result.hasScored ? 'scored' : 'not-scored';
  };

  const calculateUserScore = (userSelections) => {
    let scored = 0;
    let notScored = 0;
    let pending = 0;

    userSelections.forEach(selection => {
      const status = getPlayerStatus(selection.player);
      if (status === 'scored') scored++;
      else if (status === 'not-scored') notScored++;
      else pending++;
    });

    return { scored, notScored, pending, potentialMax: scored + pending };
  };

  const sortedSelections = [...selections].sort((a, b) => {
    const scoreA = calculateUserScore(a.selections);
    const scoreB = calculateUserScore(b.selections);

    if (scoreA.notScored === 0 && scoreB.notScored > 0) return -1;
    if (scoreB.notScored === 0 && scoreA.notScored > 0) return 1;
    if (scoreB.potentialMax !== scoreA.potentialMax) return scoreB.potentialMax - scoreA.potentialMax;
    if (scoreB.scored !== scoreA.scored) return scoreB.scored - scoreA.scored;
    return (a.user?.displayName || '').localeCompare(b.user?.displayName || '');
  });

  return (
    <Card>
      {currentGame && (
        <div className={styles.currentGame}>
          <h2 className={styles.sectionTitle}>Current Game: {currentGame.name}</h2>
          <p className={styles.deadlineInfo}>
            Deadline: {formatKickoffTime(currentGame.deadline)}
          </p>
        </div>
      )}
      
      {!isDeadlinePassed ? (
        <div className={styles.message}>
          <p>Selections will be visible after the deadline has passed.</p>
        </div>
      ) : selections.length === 0 ? (
        <div className={styles.message}>
          <p>No selections have been made for this gameweek.</p>
        </div>
      ) : (
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead className={styles.tableHeader}>
              <tr>
                <th>Rank</th>
                <th>User</th>
                <th>Selection 1</th>
                <th>Selection 2</th>
                <th>Selection 3</th>
                <th>Selection 4</th>
                <th>Selection 5</th>
              </tr>
            </thead>
            <tbody>
        {sortedSelections.map((userSelection, index) => (
          <tr key={userSelection._id || index} className={`${index % 2 === 0 ? styles.evenRow : styles.oddRow} ${styles.selectionRow}`}>
            <td>{index + 1}</td>
            <td className={styles.userName}>
              {userSelection.user ? (userSelection.user.displayName || 'Unknown User') : 'Unknown User'}
            </td>
            {userSelection.selections.map((selection, selectionIndex) => (
              <td 
                key={selectionIndex} 
                className={`${styles.playerCell} ${styles[getPlayerStatus(selection.player)]}`}
              >
                {selection.player} ({selection.team})
                {selection.kickoffTime && (
                  <div className={styles.kickoffTime}>
                    {formatKickoffTime(selection.kickoffTime)}
                  </div>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
          </table>
        </div>
      )}
    </Card>
  );
}

export default AllSelections;