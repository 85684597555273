import React, { useState, useEffect } from 'react';
import { createNewGame, getCurrentGame, updateGameDeadline, syncData, getTeams, getPlayers, updateScores, markMatchComplete } from './api';
import styles from './Admin.module.css';

function Admin() {
  const [currentGame, setCurrentGame] = useState(null);
  const [deadline, setDeadline] = useState('');
  const [newGameName, setNewGameName] = useState('');
  const [error, setError] = useState(null);
  const [syncMessage, setSyncMessage] = useState(null);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [players, setPlayers] = useState([]);
  const [scoringPlayers, setScoringPlayers] = useState([]);

  useEffect(() => {
    fetchCurrentGame();
    fetchTeams();
  }, []);

  const fetchCurrentGame = async () => {
    try {
      const game = await getCurrentGame();
      setCurrentGame(game);
      setDeadline(game ? new Date(game.deadline).toISOString().slice(0, 16) : '');
    } catch (err) {
      setError('Failed to fetch current game');
    }
  };

  const fetchTeams = async () => {
    try {
      const fetchedTeams = await getTeams();
      setTeams(fetchedTeams);
    } catch (err) {
      setError('Failed to fetch teams');
    }
  };

  const handleCreateNewGame = async () => {
    if (!newGameName.trim()) {
      setError('Please enter a name for the new game');
      return;
    }
    try {
      await createNewGame(newGameName);
      fetchCurrentGame();
      setNewGameName('');
      setError(null);
    } catch (err) {
      setError('Failed to create new game');
    }
  };

  const handleUpdateDeadline = async (e) => {
    e.preventDefault();
    try {
      await updateGameDeadline(currentGame._id, new Date(deadline));
      fetchCurrentGame();
      setError(null);
    } catch (err) {
      setError('Failed to update deadline');
    }
  };

  const handleSyncData = async () => {
    try {
      setSyncMessage('Syncing data...');
      await syncData();
      setSyncMessage('Data synced successfully!');
      setError(null);
    } catch (err) {
      console.error('Error syncing data:', err);
      setError('Failed to sync data. Please try again.');
      setSyncMessage(null);
    }
  };

  const handleTeamChange = async (e) => {
    const teamId = e.target.value;
    setSelectedTeam(teamId);
    try {
      const fetchedPlayers = await getPlayers(teamId);
      setPlayers(fetchedPlayers);
      setScoringPlayers([]);
    } catch (err) {
      setError('Failed to fetch players');
    }
  };

  const handlePlayerToggle = (playerId) => {
    setScoringPlayers(prev => 
      prev.includes(playerId) ? prev.filter(id => id !== playerId) : [...prev, playerId]
    );
  };

  const handleUpdateScores = async () => {
    try {
      await updateScores(selectedTeam, scoringPlayers);
      setError(null);
      setSyncMessage('Scores updated successfully!');
      setScoringPlayers([]);
    } catch (err) {
      setError('Failed to update scores');
    }
  };

  const handleMarkMatchComplete = async () => {
    try {
      await markMatchComplete(selectedTeam);
      setError(null);
      setSyncMessage('Match marked as complete!');
    } catch (err) {
      setError('Failed to mark match as complete');
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Admin Dashboard</h1>
      {error && <p className={styles.error}>{error}</p>}
      {syncMessage && <p className={styles.success}>{syncMessage}</p>}
      
      <div className={styles.section}>
        <h2>Current Game</h2>
        {currentGame ? (
          <div>
            <p>Game Name: {currentGame.name}</p>
            <p>Game ID: {currentGame._id}</p>
            <p>Current Deadline: {new Date(currentGame.deadline).toLocaleString()}</p>
            <form onSubmit={handleUpdateDeadline} className={styles.form}>
              <input 
                type="datetime-local" 
                value={deadline} 
                onChange={(e) => setDeadline(e.target.value)}
                className={styles.input}
              />
              <button type="submit" className={styles.button}>Update Deadline</button>
            </form>
          </div>
        ) : (
          <p>No active game</p>
        )}
      </div>

      <div className={styles.section}>
        <h2>Create New Game</h2>
        <input
          type="text"
          value={newGameName}
          onChange={(e) => setNewGameName(e.target.value)}
          placeholder="Enter new game name"
          className={styles.input}
        />
        <button onClick={handleCreateNewGame} className={styles.button}>Start New Game</button>
      </div>

      <div className={styles.section}>
        <h2>Data Management</h2>
        <button onClick={handleSyncData} className={styles.button}>Sync Data from Google Sheets</button>
      </div>

      <div className={styles.section}>
        <h2>Update Scores</h2>
        <select value={selectedTeam} onChange={handleTeamChange} className={styles.input}>
          <option value="">Select a team</option>
          {teams.map(team => (
            <option key={team._id} value={team._id}>{team.name}</option>
          ))}
        </select>
        {players.length > 0 && (
          <div className={styles.playerList}>
            <h3>Select scoring players:</h3>
            {players.map(player => (
              <label key={player._id} className={styles.playerLabel}>
                <input
                  type="checkbox"
                  checked={scoringPlayers.includes(player._id)}
                  onChange={() => handlePlayerToggle(player._id)}
                />
                {player.name}
              </label>
            ))}
            <button onClick={handleUpdateScores} className={styles.button}>Update Scores</button>
          </div>
        )}
      </div>

      <div className={styles.section}>
        <h2>Mark Match as Complete</h2>
        <button onClick={handleMarkMatchComplete} className={styles.button} disabled={!selectedTeam}>
          Mark Match Complete
        </button>
      </div>
    </div>
  );
}

export default Admin;