import React, { useState, useEffect } from 'react';
import { fetchTeams, fetchPlayers, getCurrentGame, submitSelections, fetchUserSelections } from './api';
import Card from './components/Card';
import LoadingSpinner from './components/LoadingSpinner';
import styles from './MakeSelections.module.css';

function MakeSelections() {
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState({});
  const [selections, setSelections] = useState(Array(5).fill(null));
  const [currentGame, setCurrentGame] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [existingSelections, setExistingSelections] = useState(null);
  const [submittedSelections, setSubmittedSelections] = useState(null);
  const [selectionErrors, setSelectionErrors] = useState(Array(5).fill(null));
  const [isDeadlinePassed, setIsDeadlinePassed] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const [teamsData, playersData, gameData, userSelectionsData] = await Promise.all([
        fetchTeams(),
        fetchPlayers(),
        getCurrentGame(),
        fetchUserSelections()
      ]);

      setTeams(teamsData);
      setCurrentGame(gameData);
      setIsDeadlinePassed(new Date() > new Date(gameData.deadline));

      const playersByTeam = playersData.reduce((acc, player) => {
        if (player.team && player.team.name) {
          if (!acc[player.team.name]) {
            acc[player.team.name] = [];
          }
          acc[player.team.name].push(player);
        }
        return acc;
      }, {});

      setPlayers(playersByTeam);
      
      // Check if user has already made selections for the current game
      const currentGameSelections = userSelectionsData.find(s => s.game._id === gameData._id);
      setExistingSelections(currentGameSelections);

      setIsLoading(false);
    } catch (err) {
      console.error('Error loading data:', err);
      setError('Failed to load data. Please try again later.');
      setIsLoading(false);
    }
  };

  const handleTeamChange = (index, teamName) => {
    const newSelections = [...selections];
    newSelections[index] = { team: teamName, player: null };
    setSelections(newSelections);
  };

  const handlePlayerChange = (index, playerName) => {
    const newSelections = [...selections];
    const newSelectionErrors = [...selectionErrors];
    
    // Check if the player is already selected
    const duplicateIndex = newSelections.findIndex((s, i) => i !== index && s && s.player === playerName);
    if (duplicateIndex !== -1) {
      newSelectionErrors[index] = `This player is already selected in Selection ${duplicateIndex + 1}`;
    } else {
      newSelectionErrors[index] = null;
    }
    
    newSelections[index] = { ...newSelections[index], player: playerName };
    setSelections(newSelections);
    setSelectionErrors(newSelectionErrors);
    setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    if (existingSelections) {
      setError('You have already submitted selections for this game week.');
      return;
    }

    if (isDeadlinePassed) {
      setError('Deadline has passed. Selections can no longer be submitted.');
      return;
    }

    if (selections.some(s => !s || !s.team || !s.player)) {
      setError('Please make all 5 selections before submitting.');
      return;
    }

    if (selectionErrors.some(error => error !== null)) {
      setError('Please resolve all errors before submitting.');
      return;
    }

    try {
      await submitSelections(selections);
      setSuccessMessage('Selections submitted successfully!');
      setSubmittedSelections(selections);
      setExistingSelections({ selections });
    } catch (error) {
      console.error('Error submitting selections:', error);
      setError(error.message || 'Failed to submit selections. Please try again.');
    }
  };

  if (isLoading) return <LoadingSpinner />;

  if (!currentGame) {
    return <Card><div className={styles.message}>No active game found. Please check back later.</div></Card>;
  }

  if (isDeadlinePassed) {
    return (
      <Card>
        <div className={styles.currentGame}>
          <h2 className={styles.sectionTitle}>Current Game: {currentGame.name}</h2>
          <p className={styles.deadlineInfo}>Deadline: {new Date(currentGame.deadline).toLocaleString()}</p>
        </div>
        <p className={styles.deadlineMessage}>The deadline for {currentGame.name} has passed. Selections can no longer be submitted.</p>
      </Card>
    );
  }

  if (submittedSelections || existingSelections) {
    const selectionsToShow = submittedSelections || existingSelections.selections;
    return (
      <Card>
        <div className={styles.currentGame}>
          <h2 className={styles.sectionTitle}>Current Game: {currentGame.name}</h2>
          <p className={styles.deadlineInfo}>Deadline: {new Date(currentGame.deadline).toLocaleString()}</p>
        </div>
        <div className={styles.message}>
          {submittedSelections 
            ? 'Your selections have been successfully submitted!'
            : 'You have already submitted selections for this game week.'}
        </div>
        <div className={styles.submittedSelections}>
          <h3 className={styles.subSectionTitle}>Your Selections:</h3>
          <table className={styles.selectionsTable}>
            <tbody>
              {selectionsToShow.map((selection, index) => (
                <tr key={index} className={styles.selectionRow}>
                  <td className={styles.selectionNumber}>{index + 1}</td>
                  <td className={styles.selectionTeam}>{selection.team}</td>
                  <td className={styles.selectionPlayer}>{selection.player}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    );
  }

  return (
    <Card>
      <div className={styles.currentGame}>
        <h2 className={styles.sectionTitle}>Current Game: {currentGame.name}</h2>
        <p className={styles.deadlineInfo}>Deadline: {new Date(currentGame.deadline).toLocaleString()}</p>
      </div>
      
      {error && <p className={styles.error}>{error}</p>}
      {successMessage && <p className={styles.success}>{successMessage}</p>}

      <form onSubmit={handleSubmit} className={styles.form}>
        <table className={styles.selectionsTable}>
          <tbody>
            {selections.map((selection, index) => (
              <tr key={index} className={styles.selectionRow}>
                <td className={styles.selectionNumber}>Selection {index + 1}</td>
                <td className={styles.selectionTeam}>
                  <select
                    value={selection?.team || ''}
                    onChange={(e) => handleTeamChange(index, e.target.value)}
                    className={styles.select}
                  >
                    <option value="">Select Team</option>
                    {teams.map((team) => (
                      <option key={team._id} value={team.name}>{team.name}</option>
                    ))}
                  </select>
                </td>
                <td className={styles.selectionPlayer}>
                  <select
                    value={selection?.player || ''}
                    onChange={(e) => handlePlayerChange(index, e.target.value)}
                    disabled={!selection?.team}
                    className={`${styles.select} ${selectionErrors[index] ? styles.errorSelect : ''}`}
                  >
                    <option value="">Select Player</option>
                    {selection?.team &&
                      players[selection.team]?.map((player) => (
                        <option key={player._id} value={player.name}>{player.name}</option>
                      ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {selectionErrors.some(error => error) && (
          <div className={styles.errorMessages}>
            {selectionErrors.map((error, index) => 
              error ? <p key={index} className={styles.errorMessage}>{error}</p> : null
            )}
          </div>
        )}
        <button type="submit" className={styles.submitButton}>Submit Selections</button>
      </form>
    </Card>
  );
}

export default MakeSelections;