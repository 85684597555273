const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001/api';

async function fetchWithAuth(url, options = {}) {
  const token = localStorage.getItem('token');
  const headers = {
    'Content-Type': 'application/json',
    ...options.headers,
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const response = await fetch(url, { ...options, headers });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || 'An error occurred');
  }

  return response.json();
}

export const register = async (displayName, email, password) => {
  console.log('Attempting registration with:', { displayName, email });
  try {
    const response = await fetch(`${API_BASE_URL}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ displayName, email, password }),
    });
    console.log('Registration response status:', response.status);
    
    const data = await response.json();
    console.log('Registration response:', data);
    
    if (!response.ok) {
      throw new Error(data.message || 'Registration failed');
    }
    
    return data;
  } catch (error) {
    console.error('Registration error:', error);
    throw error;
  }
};

export const login = async (email, password) => {
  const response = await fetch(`${API_BASE_URL}/api/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  });
  
  const data = await response.json();
  
  if (!response.ok) {
    throw new Error(data.message || 'Failed to log in');
  }
  
  return data;
};

export const logout = () => {
  localStorage.removeItem('token');
};

export const fetchTeams = async () => {
  const response = await fetch(`${API_BASE_URL}/teams`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });
  if (!response.ok) throw new Error('Failed to fetch teams');
  return response.json();
};

export const fetchPlayers = async () => {
  const response = await fetch(`${API_BASE_URL}/players`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });
  if (!response.ok) throw new Error('Failed to fetch players');
  return response.json();
};

export const submitSelections = async (selections) => {
  try {
    const response = await fetch(`${API_BASE_URL}/selections`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ selections })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to submit selections');
    }

    return response.json();
  } catch (error) {
    console.error('Error submitting selections:', error);
    throw error;
  }
};

export const fetchUserSelections = async () => {
  try {
    const response = await fetchWithAuth(`${API_BASE_URL}/my-selections`);
    console.log('Raw response from fetchWithAuth:', response);
    
    if (typeof response === 'string') {
      // If the response is already parsed JSON
      return JSON.parse(response);
    } else if (response instanceof Response) {
      // If it's a Response object
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to fetch selections');
      }
      return response.json();
    } else {
      // If it's already parsed JSON
      return response;
    }
  } catch (error) {
    console.error('Error in fetchUserSelections:', error);
    throw error;
  }
};

export const updateUserSelections = async (selectionId, selections) => {
  try {
    const response = await fetch(`${API_BASE_URL}/selections/${selectionId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ selections })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to update selections');
    }

    return response.json();
  } catch (error) {
    console.error('Error updating selections:', error);
    throw error;
  }
};

export const deleteUserSelection = async (selectionId) => {
  return fetchWithAuth(`${API_BASE_URL}/selections/${selectionId}`, {
    method: 'DELETE',
  });
};

export const fetchAllSelections = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/selections`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to fetch selections');
    }

    return response.json();
  } catch (error) {
    console.error('Error fetching all selections:', error);
    throw error;
  }
};

export const getCurrentUser = async () => {
  const response = await fetch('/api/user', {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  });
  if (!response.ok) throw new Error('Failed to get current user');
  const data = await response.json();
  console.log('getCurrentUser response:', data);
  return data;
};

export const createNewGame = async (name) => {
  const response = await fetch('/api/admin/games', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify({ name })
  });
  if (!response.ok) throw new Error('Failed to create new game');
  return response.json();
};

export const getCurrentGame = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/games/current`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    if (!response.ok) {
      if (response.status === 404) {
        // No active game found
        return null;
      }
      throw new Error('Failed to fetch current game');
    }
    return response.json();
  } catch (error) {
    console.error('Error fetching current game:', error);
    throw error;
  }
};

export const updateGameDeadline = async (gameId, newDeadline) => {
  return fetchWithAuth(`${API_BASE_URL}/admin/games/${gameId}/deadline`, {
    method: 'PUT',
    body: JSON.stringify({ deadline: newDeadline }),
  });
};

export const archiveGame = async (gameId) => {
  const response = await fetch(`/api/games/${gameId}/archive`, { method: 'POST' });
  if (!response.ok) throw new Error('Failed to archive game');
  return response.json();
};

export const getPlayers = async (teamId) => {
  try {
    const url = teamId 
      ? `${API_BASE_URL}/admin/team-players/${teamId}`
      : `${API_BASE_URL}/players`;
    
    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to fetch players');
    }
    const players = await response.json();
    return players;
  } catch (error) {
    console.error('Error fetching players:', error);
    throw error;
  }
};

export const getTeams = async () => {
  const response = await fetch(`${API_BASE_URL}/teams`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  });
  if (!response.ok) throw new Error('Failed to fetch teams');
  return response.json();
};

export const syncData = async () => {
  const response = await fetch(`${API_BASE_URL}/admin/sync-data`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to sync data');
  }
  return response.json();
};

// New functions for updating scores and marking matches as complete

export const updateScores = async (teamId, scoringPlayers) => {
  try {
    const response = await fetch(`${API_BASE_URL}/admin/update-scores`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ teamId, scoringPlayers })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to update scores');
    }

    return response.json();
  } catch (error) {
    console.error('Error updating scores:', error);
    throw error;
  }
};

export const markMatchComplete = async (teamId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/admin/mark-match-complete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({ teamId })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to mark match as complete');
    }

    return response.json();
  } catch (error) {
    console.error('Error marking match as complete:', error);
    throw error;
  }
};

// New function to fetch gameweek results for the leaderboard

export const getGameweekResults = async () => {
  try {
    console.log('Fetching gameweek results...');
    const token = localStorage.getItem('token');
    console.log('Token available:', !!token);
    
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 10000); // 10 second timeout

    const response = await fetch(`${API_BASE_URL}/gameweek-results`, {
      headers: {
        'Authorization': `Bearer ${token}`
      },
      signal: controller.signal
    });

    clearTimeout(timeoutId);

    console.log('Gameweek results response status:', response.status);

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Error response:', errorData);
      throw new Error(errorData.message || 'Failed to fetch gameweek results');
    }

    const data = await response.json();
    console.log('Gameweek results data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching gameweek results:', error);
    if (error.name === 'AbortError') {
      throw new Error('Request timed out. Please try again later.');
    }
    throw error;
  }
};

export const fetchCurrentGameFixtures = async () => {
  try {
    const response = await fetch('/api/current-game-fixtures');
    if (!response.ok) {
      throw new Error('Failed to fetch current game fixtures');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching current game fixtures:', error);
    throw error;
  }
};