import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import styles from './Header.module.css';

function Header() {
  const { user, logout } = useAuth();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <Link to="/" className={styles.logo}>5 Scorers</Link>
        <button className={styles.menuToggle} onClick={toggleMenu}>
          <span className={styles.burgerIcon}></span>
        </button>
        <nav className={`${styles.nav} ${menuOpen ? styles.open : ''}`}>
          <ul className={styles.navLinks}>
            <li>
              <Link to="/make-selections" className={location.pathname === '/make-selections' ? styles.active : ''} onClick={toggleMenu}>
                Make Selections
              </Link>
            </li>
            {user && (
              <li>
                <Link to="/my-selections" className={location.pathname === '/my-selections' ? styles.active : ''} onClick={toggleMenu}>
                  My Selections
                </Link>
              </li>
            )}
            <li>
              <Link to="/all-selections" className={location.pathname === '/all-selections' ? styles.active : ''} onClick={toggleMenu}>
                View All Selections
              </Link>
            </li>
            {user && user.isAdmin && (
              <li>
                <Link to="/admin" className={location.pathname === '/admin' ? styles.active : ''} onClick={toggleMenu}>
                  Admin
                </Link>
              </li>
            )}
          </ul>
          <div className={styles.userInfo}>
          {user ? (
  <>
    <span>Welcome, {user.displayName || user.email || 'User'}</span>
    <button onClick={() => { logout(); toggleMenu(); }} className={styles.logoutButton}>Logout</button>
  </>
) : (
  <>
    <Link to="/login" className={styles.authLink} onClick={toggleMenu}>Login</Link>
    <span className={styles.authSeparator}>&nbsp;|&nbsp;</span>
    <Link to="/register" className={styles.authLink} onClick={toggleMenu}>Register</Link>
  </>
)}
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;