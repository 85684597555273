import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const AdminRoute = () => {
  const { user } = useAuth();

  if (!user) {
    // If there's no user, redirect to login
    return <Navigate to="/login" replace />;
  }

  if (!user.isAdmin) {
    // If the user is not an admin, redirect to home
    return <Navigate to="/" replace />;
  }

  // If the user is an admin, render the child routes
  return <Outlet />;
};

export default AdminRoute;