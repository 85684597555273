import React from 'react';
import { useAuth } from '../AuthContext';
import LoginPrompt from './LoginPrompt';

function ProtectedRoute({ children }) {
  const { user } = useAuth();

  if (!user) {
    return <LoginPrompt />;
  }

  return children;
}

export default ProtectedRoute;