import React, { createContext, useState, useEffect, useContext } from 'react';
import { getCurrentUser } from './api';

const API_BASE_URL = process.env.REACT_APP_API_URL || '/api';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loginLoading, setLoginLoading] = useState(false);

  const loadUser = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const userData = await getCurrentUser();
        console.log('Loaded user data:', userData);
        setUser({
          token,
          ...userData
        });
      } catch (error) {
        console.error('Failed to load user:', error);
        localStorage.removeItem('token');
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    loadUser();
  }, []);

  const login = async (email, password) => {
    setLoginLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_BASE_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Login failed');
      }

      console.log('Login response:', data);

      if (!data || !data.token || !data.user) {
        throw new Error('Login response is missing token or user data');
      }

      localStorage.setItem('token', data.token);
      setUser({
        token: data.token,
        ...data.user
      });
      setLoginLoading(false);
      return data;
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message);
      setLoginLoading(false);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading, loginLoading, error, loadUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);