import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import Header from './Header';
import Home from './Home';
import MakeSelections from './MakeSelections';
import AllSelections from './AllSelections';
import MySelections from './MySelections';
import Login from './Login';
import Register from './Register';
import Admin from './Admin';
import ProtectedRoute from './components/ProtectedRoute';
import AdminRoute from './components/AdminRoute';
import LoginPrompt from './components/LoginPrompt';
import LoadingSpinner from './components/LoadingSpinner';
import styles from './App.module.css';

function AppContent() {
  const { loading, user } = useAuth();

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className={styles.app}>
      <Header />
      <main className={styles.main}>
        <div className={styles.container}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/make-selections" element={user ? <MakeSelections /> : <LoginPrompt />} />
            <Route path="/my-selections" element={<ProtectedRoute><MySelections /></ProtectedRoute>} />
            <Route path="/all-selections" element={user ? <AllSelections /> : <LoginPrompt />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/admin" element={<AdminRoute />}>
              <Route index element={<Admin />} />
              {/* Add other admin routes here if needed */}
            </Route>
          </Routes>
        </div>
      </main>
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;